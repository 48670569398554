import styled from 'styled-components'

import Logo from '@hstPublic/images/logo/logo.svg'

export const LogoStyled = styled(Logo)`
  height: 32px;

  path:nth-child(1) {
    fill: ${p => p.theme.colors.primary};
  }

  path:nth-child(2) {
    fill: ${p => p.theme.colors.backgroundAdditionalLogo};
  }
`
