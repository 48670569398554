import type { FC } from 'react'
import { memo } from 'react'

import HSTLogoShortSvg from '@hstPublic/images/logo/logoShort.svg'

import { LogoStyled } from './styles'

type Props = {
  isShort?: boolean
}

const SiteLogo: FC<Props> = ({ isShort }) => {
  return isShort ? <HSTLogoShortSvg /> : <LogoStyled />
}

export default memo(SiteLogo)
